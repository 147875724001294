import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

// UTILS
import { getParamFromURL } from 'utils/booking/common'
import EventNotificationAPI from 'api/eventNotifications'
import { useAppSelector } from 'store/store'
// ASSETS
const EventNotificationPopup = ({ area }) => {
  const [noEvent, setNoEvent] = React.useState(false)
  const [clickToClose, setClickToClose] = React.useState(true)
  const [className, setClassName] = React.useState('')
  const [eventData, setEventData] = React.useState({})

  const extraInfos = useAppSelector(state => state.extraInfos)
  const currentCustomer = useAppSelector(state => state.currentCustomer)

  const eventBoxRef = React.useRef(null)

  useEffect(() => {
    getEventPopup()
  }, [])

  const getEventPopup = async () => {
    const result = await EventNotificationAPI.getCurrentEvent(area.id, currentCustomer)
    if (_.isEmpty(result?.object) || !result?.has_event) {
      setNoEvent(true)
      return
    }

    const { object } = result
    setEventData(object)

    if (object.event_type === 'normal') {
      if (object.is_repetitive !== true && _.parseInt(localStorage.showedEventID) === object.id) {
        setNoEvent(true)
      }

      const detectSessionLockScreen = sessionStorage.getItem('lockDisplay')
      setNoEvent(!!detectSessionLockScreen)
      setClassName(!detectSessionLockScreen ? 'Overlay visible Popup-Event' : '')
      if (!sessionStorage.getItem('lockDisplay')) {
        sessionStorage.setItem('lockDisplay', 'true')
      }
      localStorage.showedEventID = object.id
    } else if ((currentCustomer.current_company_id === 0 && !object.customer_ids.include(currentCustomer.id))
      || (currentCustomer.current_company_id && !object.company_ids.include(currentCustomer.current_company_id))) {
      setClassName('Overlay visible Popup-Event Popup-Event-Block')
      setClickToClose(false)
    } else {
      setNoEvent(true)
    }
  }

  const closePopup = () => {
    if (clickToClose && eventBoxRef.current) {
      eventBoxRef.current.classList.remove('visible')
    }
  }

  const isMKT = getParamFromURL('is_mkt')
  const isPTL = getParamFromURL('ptl')
  const isHideFromWebsite = isMKT || isPTL
  if (noEvent || (extraInfos.marketing_flow === true) || isHideFromWebsite) {
    return <div />
  }
  return (
    <div ref={eventBoxRef} className={className}>
      <div className="Overlay-Lightbox-Image">
        {eventData.weblink ?
          <a href={eventData.weblink} target="_blank" rel="noreferrer">
            <img src={eventData.banner_url} className="Custom" />
          </a>
          : <img src={eventData.banner_url} className="Custom" />
        }
      </div>
      <span className="close-modal-common" onClick={closePopup}></span>
    </div>
  )

};

EventNotificationPopup.propTypes = {
  area: PropTypes.shape({}),
  currentCustomer: PropTypes.shape({}),
  extraInfos: PropTypes.shape({})
}

export default EventNotificationPopup