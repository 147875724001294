import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { assign, findIndex, forEach, omit, remove } from 'lodash'
import _ from 'lodash-es'

const initialState: any = []

const removeBlurMarker = (locations:any, isEnableGoogleMap:boolean) => {
  return locations.map((location:any) => {
      if (location.marker && _.isUndefined(location.lat) && _.isUndefined(location.lng)) {
        if(isEnableGoogleMap) {
          location.marker.setMap(null)
        } else {
          location.marker.remove()
        }
        return {
            ...location,
            marker: undefined
        }
      }
      return location
  })
}

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    resetLocations: () => initialState,
    addLocation: (state, action: PayloadAction<any>) => {
      state.push(action.payload)
    },
    insertLocation: (state, action: PayloadAction<any>) => {
      state.splice(state.length - 1, 0, action.payload)   
    },
    removeLocation: (state, action: PayloadAction<any>) => {   
      remove(state, { id: action.payload })
    },
    updateLocation: (state, action: PayloadAction<any>) => {
      const index = findIndex(state, (location: any) => parseInt(location.id, 10) === parseInt(action.payload.id, 10))
      if (index !== -1) {
        if (action.payload.locationAttrs.is_payer) {
          forEach(state, (location) => {
            assign(location, { is_payer: false })
          })
        }
        if (!action.payload.locationAttrs.is_store) {
          state[index].is_store = false
        }
        state[index] = assign({}, state[index], action.payload.locationAttrs)
      }
    },
    updateLazyAddressLocationNewBooking: (state, action: PayloadAction<any>) => {
      const index = _.findIndex(state, (location: any) => parseInt(location.id, 10) === parseInt(action.payload.id, 10))
      if (index !== -1) {
        state[index] = {
          ...state[index],
          lazyAddress: {
            ...state[index].lazyAddress,
            [action.payload.id]: action.payload.lazyAddressError
          }
        }
      }
    },
    setLocation: (state, action: PayloadAction<any>) => {
      const locations = [...state]
      removeBlurMarker(locations, action.payload.isEnableGoogleMap)
      return action.payload.locations
    },
    setLocationsWithoutAttrs: (state, action: PayloadAction<any>) => {
      const newLocations = (state || []).map((item: any) => ({ ...item }))
      forEach(newLocations, (location, index) => {
        assign(location, omit(action.payload.locations[index], action.payload.locationAttrs))
      })
      return newLocations
    },
    removeBlurMarker: (state, action: PayloadAction<any>) => {
      const locations = [...state]
      return removeBlurMarker(locations, action.payload)
    },
    createLocationsEdit: (state, action: PayloadAction<any>) => action.payload,
    backupLocations: (state, action: PayloadAction<any>) => action.payload.locations,
  },
  extraReducers: () => {},
})
export const locationsActionsCreator = locationsSlice.actions

export default locationsSlice
