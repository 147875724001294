import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import $ from 'jquery'
import _ from 'lodash'

// UTILS
import { CPODUtils } from 'utils/booking/CPODUtils'
import { Utils } from 'utils/Utils'
import { getLocationDisplayInfos, hideCountryOnAddress, LocationPopover } from 'utils/LocationUtil'
import locationUtils from 'utils/common/location'
import I18n from 'i18n/i18n'
import toastr from 'utils/toast';
// API
// ACTIONS
import * as stepLocationActions from 'store/actions/new_booking/locationActionCreators'
import * as stepActionCreators from 'store/actions/new_booking/stepActionCreators'
// COMPONENTS
import CPOD from '../../common/CPOD'
import CountdownRemaining from '../../common/CountdownRemaining'

// CONSTANTS
import { POPUP_ID_COD_INVOICE_FEE } from 'constants/common/popupConstants'
import { LONG_HAUL } from 'constants/bookingConstants'
import { documentReturnActionsCreator } from 'store/toolkit/newBooking/documentReturn.reducer'
import { locationsActionsCreator } from 'store/toolkit/locations/locations.reducer'
import { tmpLocationActionsCreator } from 'store/toolkit/newBooking/updateTmpLocation.reducer'
import { COUNTRY_LANGUAGE_PH, COUNTRY_LANGUAGE_TH } from 'constants/dashBoardAnalyticsConstants'
// ASSETS

const mapStateToProps = state => ({
  bookAgainDetails: state.bookAgainDetails,
  currentCustomer: state.currentCustomer,
  extraInfos: state.extraInfos,
  locations: state.locations,
  tmpLocations: state.tmpLocations,
  timeType: state.timeType,
  documentReturn: state.documentReturn,
  contacts: state.contacts,
  personalContacts: state.personalContacts,
  checkLocations: state.checkLocations,
})

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(stepLocationActions, dispatch),
    updateTmpLocationActions: bindActionCreators(tmpLocationActionsCreator, dispatch),
    documentActions: bindActionCreators(documentReturnActionsCreator, dispatch),
    locationsActions: bindActionCreators(locationsActionsCreator, dispatch),
    stepActions: bindActionCreators(stepActionCreators, dispatch)
  }
}
class CODAndPODPopover extends React.Component {
  static verifyEnableDocument(extraInfos) {
    if (_.isUndefined(extraInfos.document_return_address)) {
      return false
    }

    return extraInfos.document_return_address.enabled
  }

  constructor(props) {
    super(props)
    this.state = {
      phoneNumber: undefined,
      address: undefined,
      addressObject: props.documentReturn,
    }

    this.initLoaded = true

    this.recipient_nameRef = {}
    this.cityRef = {}
    this.stateRef = {}
    this.postal_codeRef = {}
    this.latitude = 0
    this.longitude = 0
    this.optional_notesRef = {}

    this.onRef = this.onRef.bind(this)
    this.handleAddMe = this.handleAddMe.bind(this)
    this.handleSelectContact = this.handleSelectContact.bind(this)
    this.handlePhoneChange = this.handlePhoneChange.bind(this)
    this.handleAddress = this.handleAddress.bind(this)
    this.handleMappingAddress = this.handleMappingAddress.bind(this)
    // this.handleAddressKeyDown = this.handleAddressKeyDown.bind(this)
    this.handleUpdateAddressComponentForDocumentReturn = this.handleUpdateAddressComponentForDocumentReturn.bind(this)
    this.escFunction = this.escFunction.bind(this)
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false)
  }

  componentWillReceiveProps(nextProps) {
    const {
      bookAgainDetails, extraInfos, documentReturn
    } = this.props
    const { addressObject } = this.state

    const documentReturnNew = nextProps.documentReturn
    if (documentReturn !== documentReturnNew && this.initLoaded) {
      this.initLoaded = false
      const { document_return_address: documentReturnAddress } = extraInfos
      const bookingTracking = bookAgainDetails && bookAgainDetails.booking_tracking
        ? bookAgainDetails.booking_tracking : documentReturnAddress

      if (bookingTracking) {
        this.setState({
          phoneNumber: bookingTracking.recipient_phone,
          address: bookingTracking.address_1,
        })
        this.latitude = bookingTracking.latitude
        this.longitude = bookingTracking.longitude
        this.recipient_nameRef.value = bookingTracking.recipient_name
        this.cityRef.value = bookingTracking.city
        this.stateRef.value = bookingTracking.state
        this.postal_codeRef.value = bookingTracking.postal_code
        this.optional_notesRef.value = bookingTracking.notes
      }
    }

    const formattedAddress = (_.isUndefined(addressObject) || _.isNull(addressObject)) ? '' : addressObject.formatted_address
    if (documentReturnNew.address_1 !== (formattedAddress || '')) {
      this.setState({
        addressObject: documentReturnNew
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false)
  }

  onChangePODCheckboxHandler(id, event) {
    const { updateTmpLocationActions, extraInfos } = this.props
    const checked = event.target.checked
    //old code FTL
    // const location = _.find(locations, { id })
    // location.pre_selected = checked
    if (checked) {
      updateTmpLocationActions.updateTmpLocation({ id, locationAttrs: { need_pod: checked, pre_selected: checked } })
    } else if (extraInfos.enable_cod_pod_new_rule) {
      updateTmpLocationActions.updateTmpLocation({ id, locationAttrs: { need_pod: checked, need_cod: false, pre_selected: checked } })
    } else {
      updateTmpLocationActions.updateTmpLocation({ id, locationAttrs: { need_pod: checked, pre_selected: checked } })
    }
  }

  onChangeCODCheckboxHandler(id, event) {
    const { updateTmpLocationActions, extraInfos } = this.props
    const checked = event.target.checked

    if (!checked) {
      updateTmpLocationActions.updateTmpLocation({ id, locationAttrs: { need_cod: checked } })
    } else if (extraInfos.enable_cod_pod_new_rule) {
      updateTmpLocationActions.updateTmpLocation({
        id, locationAttrs:
        {
          need_cod: checked,
          need_pod: checked
        }
      })
    } else {
      updateTmpLocationActions.updateTmpLocation({ id, locationAttrs: { need_cod: checked } })
    }
  }

  onChangeFormat(id, key, event) {
    const { updateTmpLocationActions, extraInfos } = this.props
    const value = (extraInfos.country_language === COUNTRY_LANGUAGE_PH || extraInfos.country_language === COUNTRY_LANGUAGE_TH) ? event.target.value : event.target.value.replace(/[^\d]/g, '')
    this[`refInput${id}`].value = value
    updateTmpLocationActions.updateTmpLocation({ id, locationAttrs: { [key]: parseFloat(value) } })
  }

  getAddressComponents(addressObject, cloneAddressComponents) {
    return (!_.isEmpty(addressObject) && !_.isEmpty(addressObject.address_components))
    ? addressObject.address_components : cloneAddressComponents
  }

  getTmpLocations(tmpLocation) {
    return tmpLocation.need_cod === true && _.includes([undefined, '', 0, '0', NaN], tmpLocation.cod_invoice_fees)
  }

  async onConfirmHandler() {
    const { phoneNumber, address, addressObject } = this.state
    const {
      documentActions,
      extraInfos,
      tmpLocations,
      documentReturn: cloneObj,
      checkLocations,
      bookAgainDetails,
    } = this.props

    if ($('#booking_locations_attributes_cpod').parent().parent().hasClass('error')) {
      return false
    }

    let location
    const codLimit = extraInfos.cod_amount_limit_alert || 0
    const keys = CPODUtils.keys()
    const isOutOfLimit = this.checkOutOfLimit(codLimit)
    const documentReturn = CPODUtils.cloneDocumentReturn({
      recipient_name: this.recipient_nameRef,
      recipient_phone: phoneNumber,
      address_1: address,
      city: this.cityRef,
      state: this.stateRef,
      postal_code: this.postal_codeRef,
      latitude: this.latitude,
      longitude: this.longitude,
      address_components: this.getAddressComponents(addressObject, cloneObj.address_components),
      notes: this.optional_notesRef,
    })

    // Validate?
    // eslint-disable-next-line consistent-return
    const invalidKeys = CPODUtils.invalidDocumentReturnAddress(documentReturn, keys)
    const validLocationsPOD = !!_.find(tmpLocations, ['need_pod', true])
    const isShowCPOD = CPODUtils.verifyNewGenCPOD({ checkLocations, bookAgainDetails })

    const isValid = await locationUtils.validateLazyAddress(addressObject || documentReturn, extraInfos)
    if (!isValid) {
      invalidKeys.push('address_1')
    }

    if (_.size(invalidKeys) && validLocationsPOD && isShowCPOD) {
      documentActions.updateDocumentReturn({
        ...documentReturn,
        invalidKeys
      })
      return true
    }

    // remove Class select-error cpod box-item
    documentActions.updateDocumentReturn({
      ...documentReturn,
      is_required: false
    })

    if (extraInfos.enable_cod_pod_new_rule) {
      location = _.find(
        tmpLocations,
        tmpLocation => this.getTmpLocations(tmpLocation)
      )
    }

    if (_.isEmpty(location) && !isOutOfLimit) {
      this.handleConfirmCodAndPod({
        validLocationsPOD,
        documentReturn,
        invalidKeys,
        isShowCPOD
      })
    } else if (isOutOfLimit) {
      const element = document.querySelector(`#${POPUP_ID_COD_INVOICE_FEE}${tmpLocations[tmpLocations.length - 1].id}`)
      if (element) {
        element.focus()
      }
      toastr.error(I18n.t('errors.location.maximum_cod_per_booking', { limit: codLimit.toLocaleString(extraInfos.country_language, { minimumFractionDigits: 0 }), currency_code: extraInfos.currency }))
    } else {
      toastr.remove()
      toastr.error(I18n.t('errors.location.blank_cod_invoice_fees'))
    }
  }

  handleConfirmCodAndPod = ({
    validLocationsPOD,
    documentReturn,
    invalidKeys,
    isShowCPOD
  }) => {
    const {
      documentActions,
      actions,
      tmpLocations,
      locationsActions
    } = this.props
    Promise.resolve(
      locationsActions.setLocationsWithoutAttrs({ locations: tmpLocations, locationAttrs: ['marker'] })
    ).then(() => {
      if (validLocationsPOD) {
        const obj = {
          ...documentReturn,
          invalidKeys
        }
        documentActions.updateDocumentReturn(obj)
        actions.updateCODPODFees(obj, {})
        if (isShowCPOD) {
          window.Moengage.track_event('EM POD Selected')
        } else {
          window.Moengage.track_event('SF POD Selected')
        }
      } else {
        actions.resetBookingFees()
      }
    }).then(() => {
      this.hideCODandPodPopover()
    })
  }

  onRef(ref, keyValue) {
    switch (keyValue) {
      case 'recipient_name':
        this.recipient_nameRef = ref
        break
      case 'city':
        this.cityRef = ref
        break
      case 'state':
        this.stateRef = ref
        break
      case 'postal_code':
        this.postal_codeRef = ref
        break
      case 'notes':
        this.optional_notesRef = ref
        break
      default:
        break
    }
  }

  handleUpdateAddressComponentForDocumentReturn(addressComponents) {
    const { documentActions, documentReturn } = this.props || {}
    const { address_components: currentAddressComponents } = documentReturn || {}
    if (!_.isEqual(addressComponents, currentAddressComponents)) {
      const obj = {
        ...documentReturn,
        address_components: addressComponents
      }
      documentActions.updateDocumentReturn(obj)
    }
  }

  formatCurrency(value, location) {
    if (value) {
      const { extraInfos } = this.props
      this[`refInput${location.id}`].value = parseFloat(value) ? Utils.formatFee(value, extraInfos.country_language, undefined, undefined, 8) : location.cod_invoice_fees || 0
    }
  }

  clearFormatCurrency(value, id) {
    if (value) {
      this[`refInput${id}`].value = value
    }
  }

  hideCODandPodPopover() {
    const { documentActions, documentReturn } = this.props
    const obj = {
      ...documentReturn,
      is_show: false
    }

    documentActions.updateDocumentReturn(obj)
    LocationPopover.addClass($('body'), 'Step-Route')
    this.setState({ phoneNumber: documentReturn.recipient_phone, address: documentReturn.address_1 })
  }

  checkOutOfLimit(codLimit) {
    const { tmpLocations } = this.props
    if (tmpLocations && tmpLocations.length > 0 && codLimit > 0) {
      let total = 0
      for (const location of tmpLocations) {
        const codAmount = parseInt(location.cod_invoice_fees, 10)
        if(location.need_cod) {
          if (codAmount > codLimit) {
            return true
          }
          total += codAmount
        }
      }
      if (total > codLimit) {
        return true
      }
    }
    return false
  }

  handleAddMe() {
    const { currentCustomer } = this.props
    this.recipient_nameRef.value = currentCustomer.name
    this.setState({ phoneNumber: currentCustomer.phone })
  }

  handleSelectContact({
    name,
    phone,
    address,
    latitude = 0,
    longitude = 0,
    address_components: addressComponents,
  }) {
    this.recipient_nameRef.value = name
    this.setState({ phoneNumber: phone, address })
    this.latitude = latitude
    this.longitude = longitude

    this.handleMappingAddress(addressComponents, {
      city: 'administrative_area_level_2',
      state: 'administrative_area_level_1',
      postal_code: 'postal_code',
    })
    const contact = {
      name,
      phone,
      address,
      latitude,
      longitude,
      address_components: addressComponents,
    }
    this.setState({ addressObject: contact })
  }

  handlePhoneChange(phone) {
    this.setState({ phoneNumber: phone })
  }

  handleAddress(objectValue, isAutoComplete = false) {
    const { address } = objectValue
    if (!isAutoComplete && !address) {
      this.cityRef.value = null
      this.stateRef.value = null
      this.postal_codeRef.value = null
      this.latitude = 0
      this.longitude = 0
    }

    this.setState({ address: isAutoComplete ? address.addressName : objectValue, addressObject: address }, () => {
      if (isAutoComplete) {
        this.latitude = address?.location?.latitude || 0
        this.longitude = address?.location?.longitude || 0
      }
    })
  }

  handleMappingAddress(addressComponents, config) {
    if (_.isEmpty(addressComponents)) return false
    Object.keys(config).forEach((key) => {
      addressComponents.forEach((obj) => {
        if (obj.types.includes(config[key])) {
          this[`${key}Ref`].value = obj.long_name
        }
      })
    })
    return true
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.hideCODandPodPopover()
    }
  }

  // handleAddressKeyDown(event) {
  //   const keyCode = event.which
  //   const currentTarget = event.target

  //   if (!_.isEmpty(currentTarget.value) && (keyCode === 8 || keyCode === 46 || keyCode === 13)) {
  //     this.setState({ address: currentTarget.value })
  //   }
  // }

  renderLocation(location, index) {
    const {
      locations,
      tmpLocations,
      extraInfos,
      timeType,
      checkLocations,
      bookAgainDetails,
      updateTmpLocationActions
    } = this.props

    const locationDisplayInfos = getLocationDisplayInfos(tmpLocations.length, index)
    const checkBoxClass = location.can_toggle_need_pod ? 'Popover-SubItem' : 'Popover-SubItem disabled'
    const origialLocation = _.find(locations, { id: location.id })
    const isShowCountryOnAddress = extraInfos.is_show_country_on_address
    const locationName = isShowCountryOnAddress
      ? location.name
      : hideCountryOnAddress(location.address_components, location.name)
    const isShowCPOD = CPODUtils.verifyNewGenCPOD({ checkLocations, bookAgainDetails })
    const selectedLocation = location.pre_selected || location.need_pod
    const countryLanguage = extraInfos.country_language

    if (_.isEmpty(origialLocation)) return null
    return !_.isEmpty(origialLocation.marker) && (
      <div className={`${_.size(tmpLocations) - 1 !== index ? 'Border-Bottom Border-Color-White' : ''} pb10`} key={index}>
        <div className="Popover-Item">
          <div className={locationDisplayInfos.className}>
            {locationDisplayInfos.label}
          </div>
          <label className="default-font">
            {locationName}
          </label>
        </div>
        <div className={checkBoxClass}>
          <div className="Overlay-Checkbox White-Checkbox">
            <input
              type="checkbox"
              tabIndex="1"
              id={`location_pod_${index}`}
              checked={selectedLocation}
              onChange={e => this.onChangePODCheckboxHandler(location.id, e)}
            />
            <label htmlFor={`location_pod_${index}`}>
              <i className="b material-icons Icon">
                check
              </i>
              {I18n.t('webapp.new_booking.step_2.document_handling')}
            </label>
          </div>
        </div>

        {timeType !== LONG_HAUL && !isShowCPOD && (
          <div className="Popover-SubItem mt10">
            <div className="Overlay-Checkbox White-Checkbox">
              <input
                type="checkbox"
                tabIndex="1"
                id={`location_cod_${index}`}
                checked={location.need_cod}
                onChange={e => this.onChangeCODCheckboxHandler(location.id, e)}
              />
              <label htmlFor={`location_cod_${index}`}>
                <i className="b material-icons Icon">
                  check
                </i>
                {I18n.t('webapp.new_booking.step_2.cod')}
              </label>
            </div>
          </div>
        )}

        {location.need_pod && !location.need_cod && (
          <div className="Popover-SubItem mt10 mb-10-imp">
            <CountdownRemaining
              className="w100 reset m Border-Default"
              classNameBox="flex-index"
              classTextarea="default-font h80px"
              placeholder={I18n.t('webapp.label.add_note')}
              remainPercentToShow={extraInfos.remaining_characters_in_note_fields}
              value={location.pod_note}
              handleChange={value => updateTmpLocationActions.updateTmpLocation({ id: location.id, locationAttrs: { pod_note: value, cod_note: value } })}
            />
          </div>
        )}
        {timeType !== LONG_HAUL && location.need_pod && location.need_cod && (
          <div className="Popover-SubItem mt10 mb-10-imp">
            <CountdownRemaining
              className="w100 reset m Border-Default"
              classNameBox="flex-index"
              classTextarea="default-font h80px"
              placeholder={I18n.t('webapp.label.add_note')}
              remainPercentToShow={extraInfos.remaining_characters_in_note_fields}
              value={location.cod_note}
              handleChange={value => updateTmpLocationActions.updateTmpLocation({ id: location.id, locationAttrs: { pod_note: value, cod_note: value } })}
            />
          </div>
        )}

        {timeType !== LONG_HAUL && location.need_cod && extraInfos.enable_cod_pod_new_rule && !isShowCPOD && (
          <div className="Popover-SubItem">
            <div className="Input reset m Border-Default">
              <div className="Line-bg h30px w30 flex-center small-font default-color Custom-Input-Group">
                {extraInfos.currency}
              </div>
              <input
                id={`${POPUP_ID_COD_INVOICE_FEE}${location.id}`}
                ref={(refInput) => { this[`refInput${location.id}`] = refInput }}
                placeholder={I18n.t('webapp.label.add_cod_invoice_fees')}
                className="Hide-Arrow-Number"
                tabIndex="1"
                defaultValue={Utils.formatFee(location.cod_invoice_fees || 0, countryLanguage, undefined, 0, 8)}
                onChange={e => this.onChangeFormat(location.id, 'cod_invoice_fees', e)}
                onFocus={() => this.clearFormatCurrency(location.cod_invoice_fees, location.id)}
                onBlur={e => this.formatCurrency(e.target.value, location)}
              />
            </div>
          </div>
        )}
      </div>
    )
  }

  renderButtons() {
    return (
      <div className="flex">
        <div
          className="Button bold White-text White-border White-bg-hover Dark-Green-text-hover flex-index mr5 ml5"
          onClick={() => this.hideCODandPodPopover()}
        >
          {I18n.t('webapp.action.cancel')}
        </div>
        <div
          className="Button bold White-bg Dark-Green-text Dark-Green-bg-hover Border-Color-White-hover White-text-hover flex-index ml5 mr5 Border-Default"
          onClick={() => this.onConfirmHandler()}
        >
          {I18n.t('webapp.action.confirm')}
        </div>
      </div>
    )
  }

  render() {
    const {
      tmpLocations, documentReturn, checkLocations, extraInfos, bookAgainDetails
    } = this.props
    const isShowCPOD = CPODUtils.verifyNewGenCPOD({ checkLocations, bookAgainDetails })

    if (!_.size(documentReturn) || !documentReturn.is_show) return null
    return (
      <div className="CodPod Popover custom step-2 visible">
        <div
          className="Popover-Arrow Custom"
          style={{
            top: $('.CodPod-JS').length ? $('.CodPod-JS').position().top + 25 : 0,
            zIndex: -1,
          }}
        />
        <div className="flex flex-column h100">
          <div className="flex-index pt10 pr10 pb10 pl10 Custom-Scroll-Group min-h-180">
            <div
              ref={(ref) => {
                this.tmpLocationsContainer = ref
                if (ref && ref.clientHeight > window.outerHeight / 2) {
                  $('.cpod-container').removeClass('flex-index')
                }
              }}
              className="Popover-List relative-imp reset mb p-imp"
            >
              <div className="vertical-scroll Popover-List-Scroll h100">
                <div className="Popover-Item">
                  <h3 className="Border-Bottom Border-Color-White pb10">
                    {I18n.t('webapp.new_booking.step_2.cod_and_pod_desc')}
                  </h3>
                </div>
                {tmpLocations.map((location, index) => this.renderLocation(location, index))}
              </div>
            </div>
          </div>
          {isShowCPOD && _.find(tmpLocations, ['need_pod', true]) ? (
            <CPOD
              {...this.state}
              {...this.props}
              isDefaultView
              enabledAutoCompleted
              verifyEnableDocument={CODAndPODPopover.verifyEnableDocument(extraInfos)}
              containerClassName="Custom-Scroll-Group cpod-container flex-index flex flex-column Dark-Green-bg pt10 pr5 pb10 pl5"
              renderActions={() => this.renderButtons()}
              handleAddMe={this.handleAddMe}
              handleSelectContact={this.handleSelectContact}
              handlePhoneChange={this.handlePhoneChange}
              handleAddress={this.handleAddress}
              handleMappingAddress={this.handleMappingAddress}
              // handleAddressKeyDown={this.handleAddressKeyDown}
              onRef={this.onRef}
              handleUpdateAddressComponentForDocumentReturn={this.handleUpdateAddressComponentForDocumentReturn}
            />
          ) : (
            <div className="pl5 pr5 pb10">
              {this.renderButtons()}
            </div>
          )}
        </div>
      </div>
    )
  }
}

CODAndPODPopover.propTypes = {
  currentCustomer: PropTypes.shape({}).isRequired,
  extraInfos: PropTypes.shape({}).isRequired,
  documentReturn: PropTypes.shape({}).isRequired,
  documentActions: PropTypes.shape({}).isRequired,
  actions: PropTypes.shape({}).isRequired,
  bookAgainDetails: PropTypes.shape({}),
  checkLocations: PropTypes.shape({}),
  tmpLocations: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.string,
  ]),
  timeType: PropTypes.string,
  locations: PropTypes.arrayOf(PropTypes.object),
  stepActions: PropTypes.shape({}).isRequired
}

CODAndPODPopover.defaultProps = {
  timeType: undefined,
  bookAgainDetails: {},
  checkLocations: {},
  tmpLocations: [],
  locations: [],
}

export default connect(mapStateToProps, mapDispatchToProps)(CODAndPODPopover)
