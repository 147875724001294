import { STATUS_DELIVERY_IN_PROGRESS, STATUS_DRIVER_DECLINED_BOOKING } from "constants/bookingConstants"
import { Utils } from "utils/Utils"
import firebaseInit from "utils/firebaseInit"

const handleActionOnFirebase = (snapshot, bookingId, navigate) => {
  switch (snapshot.key) {
    case 'status': {
      const status = snapshot.val()
      if ((status === STATUS_DELIVERY_IN_PROGRESS || status === STATUS_DRIVER_DECLINED_BOOKING)) {
        navigate(`/bookings/${bookingId}`)
      }
      break
    }
    case 'canceled_by_driver': {
      if (snapshot.val()) Utils.handleGoToDetailsFromPayment(bookingId, `/bookings/${bookingId}`, navigate)
      break
    }
    default:
      break
  }
}

const listenBookingPayment = (bookingId, navigate) => (dispatch, getState) => {
  const { currentCustomer } = getState()
  const bookingPath = `customers/${currentCustomer.id}/bookings/${bookingId}`
  const firebaseBooking = firebaseInit.listen(bookingPath)
  firebaseBooking.on('child_added', (snapshot) => {
    handleActionOnFirebase(snapshot,bookingId, navigate)
  })

  firebaseBooking.on('child_changed', (snapshot) => {
    handleActionOnFirebase(snapshot,bookingId, navigate)
  })

  firebaseBooking.on('child_removed', (snapshot) => {
    if (snapshot.key === 'canceled_by_driver') {
      Utils.handleGoToDetailsFromPayment(bookingId, `/bookings/${bookingId}`, navigate)
    }
  })
}

export default listenBookingPayment