import React, { useEffect } from 'react'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import I18n from 'i18n/i18n'

import { renderIconTimeType } from 'utils/booking/common'
import ClosuresUtils from 'utils/booking/ClosuresUtils'
import CustomerAPI from 'api/customers'

const MiniTooltip = () => {
  const currentCustomer = useSelector(state => state.currentCustomer)
  const [booking, setBooking] = React.useState({})
  const navigate = useNavigate()
  const setIntervalApi = React.useRef(null)

  useEffect(() => {
    updateBookingInterval()
    setIntervalApi.current = setInterval(() => {
      updateBookingInterval()
    }, 60000)
    return () => {
      clearInterval(setIntervalApi.current)
    }
  }, [])

  const updateBookingInterval = () => {
    const params = { 'include': ['reimbursements'], version: 2 }
    const isAvailableCurrentCustomer = !_.isEmpty(currentCustomer.authentication_token)
    if (_.parseInt(currentCustomer.current_company_id) > 0) {
      _.assign(params, {
        'company_ids': [currentCustomer.current_company_id]
      })
    }
    if (isAvailableCurrentCustomer) {
      CustomerAPI.getBookingEarliest(params, (res) => {
        setBooking(res)
      })
    }
  }

  if (_.isEmpty(booking)) { return null }
  const timeTypeImage = renderIconTimeType(booking.time_type)
  const titleInfo = booking.is_shopping
    ? `${I18n.t('webapp.shopping.title')}`
    : `${ClosuresUtils.showBookingStatusText(booking)}`
  return (
    <button
      className="Block-MiniTooltip Box-Sizing Border-Box Radius-default absoute cur-pointer pt10 pb10 pr15 pl15 flex flex-index"
      onClick={() => {
        navigate(`/bookings/${booking.id}`)
      }}
    >
      <div className="flex-index flex-start mr10">
        <img src={timeTypeImage} alt="block tooltip" height="30" />
        <div className="ml10 flex flex-column">
          <span className="default-font Yellow-text default-medium-font">
            {I18n.t('webapp.tally.ongoing_booking')}
          </span>
          <span className="default-font White-text default-medium-font">
            {booking.id}
            <span className="dot-separate" />
            {titleInfo}
          </span>
        </div>
      </div>
    </button>
  )
}

export default MiniTooltip
